<script lang="ts">
  import { uiTheme } from '@common/constants'

  import SyncLoader from '@components/Loaders/SyncLoader.svelte'
  import Loadable from 'svelte-loadable'

  export let loader: () => Promise<any>

  const logError = (err) => {
    console.error(err)
    return `${err}`
  }
</script>

<Loadable let:component {loader}>
  <div
    data-theme={uiTheme}
    slot="loading"
    class="flex items-center justify-center w-full h-full overflow-hidden bg-base-300"
  >
      <SyncLoader color="white" size={20} />
  </div>
  <div
    data-theme={uiTheme}
    slot="error"
    let:error
    class="justify-center w-full h-full overflow-hidden flex-center bg-base-300 text-base-content"
  >
    {logError(error)}

    <br />
  </div>
  <slot {component} />
</Loadable>
