import './mocks.js'
import './index.css'
import 'virtual:windi.css'
import "semantic-ui-css/components/icon.min.css"
import "semantic-ui-css/components/breadcrumb.min.css"
import App from './AppAsyncLoad.svelte'


// import("./index.css")

document.body.innerHTML = ""

const app = new App({
	target: document.body,
})

export default app
