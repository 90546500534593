export const uiTheme = "business";
export const LOCAL_WEBRTC_SIGNALLING = "ws://localhost:4444"
export const IS_DEV = import.meta?.env?.DEV


export const cacheName = 'notebrowser';
export const channelName = 'notebrowser-messages';
export const urlPrefix = "/share-api/"


export const shareContainerContextKey = {};
