<script lang="ts">
  import { isMobileDevice } from '@common/isMobileDevice'
  import LazyLoad from './AsyncLoader/LazyLoad.svelte'

  const isMobile = isMobileDevice()
</script>

{#if isMobile}
  <LazyLoad let:component={App} loader={() => import(`./AppMobile.svelte`)}>
    <svelte:component this={App} />
  </LazyLoad>
{:else}
  <LazyLoad let:component={App} loader={() => import(`./App.svelte`)}>
    <svelte:component this={App} />
  </LazyLoad>
{/if}

