import { noop } from 'lodash'

let version = 'sadasd'

const noopPromise = async () => {}

window.global = window
window.require = module => {
  switch (module) {
    case 'electron':
      return {
        ipcRenderer: {
          on: noop,
          once: noop,
          send: noop,
        },
        remote: {
          app: {
            getPath: noop,
            getAppPath: noop,
            getVersion: () => version,
          },
          getGlobal: noop,
          getCurrentWebContents: noop,
        },
        webFrame: {
          setZoomFactor: noop,
        },
      }
    case 'path':
      return {
        join: noop,
      }

    case 'url':
      return {
        pathToFileURL: noop,
        fileURLToPath: noop,
      }

    case 'fs-extra':
      return {
        pathExists: noopPromise,
        ensureDir: noopPromise,
        ensureFile: noopPromise,
        writeJson: noopPromise,
      }

    case 'fs':
      return {
        promises: {
          readdir: noopPromise,
        },
      }

    default:
      const moduleArr = module.split('.')

      if (module.length === 1) {
        return {}
      }
      const data = {}
      let temp = {}

      moduleArr.forEach((item, i) => {
        temp[item] = {}
        data[item] = temp
        temp = data[item]

        if (i === moduleArr.length - 1) {
          data[item] = noop
        }
      })
      return data
  }
}
